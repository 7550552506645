import React, { useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { useDispatch, useSelector } from "react-redux"
import { search as professionalServiceSearch } from "services/vitta-core/professional"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import { showHistoricExport } from "services/vitta-core/professional-transfer-invoices"
import { showTransferNoteExport } from "services/vitta-core/professional-transfer-invoices"
import debounce from "debounce-promise"
import PROF_TRANSFER_INVOICE_STATUS from "utils/consts/prof-transfer-invoice-status"
import { showOdontoExecExport } from "services/vitta-core/sales-order-item-exec"
const today = new Date().toLocaleDateString("pt-BR")
const todayOnlyDate =
    today.substr(6, 4) + "-" + today.substr(3, 2) + "-" + today.substr(0, 2)

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        onChange={option => {
            setFieldValue(field.name, option)
        }}
    />
)

const NumberInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue },
    ...props
}) => <Input type="number" {...field} {...props} />

const OdontoExec = () => {
    const [reportControl, setReportControl] = useState({
        filter: null,
        show: false,
    })

    const [reportStyle, setReportStyle] = useState(null)
    const [controlState, setControlState] = useState(false)
    const history = useHistory()

    const userAuth = useSelector(state => state.auth.access?.user)
    const Scope = useSelector(state => state.scope)
    const dispatch = useDispatch()
    const unitsOptions = useMemo(() => {
        if (!userAuth?.units) return []

        let options_list = []
        userAuth.units.forEach(element => {
            options_list.push({
                label: element.name,
                value: element.id,
                cnpj: element.cnpj,
            })
        })
        return options_list
    }, [userAuth])
    // const controlPermissionProfessional = useMemo(() => {
    //     let found = false
    //     for (let i = 0; i < userAuth?.access_roles?.length; i++) {
    //         let record = userAuth?.access_roles[i]
    //         for (let j = 0; j < record?.permissions?.length; j++) {
    //             let result = record?.permissions[j]
    //             if (result.name == "view_appointment") {
    //                 found = true
    //                 break
    //             }
    //         }
    //     }

    //     if (found) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }, [userAuth])
    const loadProfessionalInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                const statusActive = 1
                let response = await professionalServiceSearch(
                    { term: inputValue, status: statusActive },
                    1,
                    20
                )

                let list_values = []

                list_values.push({
                    label: "Todos",
                    value: '',
                })


                response.data.forEach(element => {
                    list_values.push({
                        label: element.name,
                        value: element.id,
                    })
                })

                return resolve(list_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceOptionsProfessional = debounce(
        loadProfessionalInputOptions,
        600
    )

    const onSubmit = async (data_form) => {

        if(!data_form?.unit_id || data_form?.unit_id?.length <= 0)
        {
            dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: "Preencha o Campo Unidade!",
                    type: "error",
                    showConfirm: true,
                })
            )
            return;
        }
        try {
            
            let auxData = {}
            auxData.professional = data_form.professional_id?.value
            auxData.unit = data_form.unit_id.map(e=>parseInt(e?.value))?.join(',')
            auxData.start_date_exec = data_form.start_date_exec
            auxData.end_date_exec = data_form.end_date_exec
            
          

            if(data_form.start_sales_order) auxData.start_sales_order = data_form.start_sales_order
            if(data_form.end_sales_order)  auxData.end_sales_order = data_form.end_sales_order


            // if (
            //     !auxData.unit 
                
            // ) {
            //     dispatch(
            //         showSweetAlertDialog({
            //             title: "Ops... ",
            //             text: "Preencha o Campo Unidade!",
            //             type: "error",
            //             showConfirm: true,
            //         })
            //     )

            //     return
            // }
            

            setControlState(true)
            let response = await showOdontoExecExport({
                unit_id: auxData.unit,
                professional_id: auxData.professional,
                start_date_exec: auxData.start_date_exec,
                end_date_exec: auxData.end_date_exec,
                start_sales_order: auxData.start_sales_order? auxData.start_sales_order:null,
                end_sales_order: auxData.end_sales_order? auxData.end_sales_order:null
                
            })


            var headers = response.headers
            var blob = new Blob([response.data], {
                type: headers["content-type"],
            })
            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = "Execução de Serviços"
            link.click()
            setControlState(false)
            return






        } catch (e) {
            console.log(e)
            setControlState(false)

        }
    }




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Execução de Serviços" />
                    <Row>
                        <Col xs="12">
                            <Row className="d-flex justify-content-center">
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <h3
                                                align="center"
                                                className="mb-4 mt-4"
                                            >
                                                Execução de Serviços{" "}
                                                <i className="fas fa-check fa-lg ml-2"></i>
                                            </h3>
                                            <Formik
                                                initialValues={{
                                                    professional_id: null,
                                                    unit_id: Scope.unit
                                                        ? {
                                                            label: Scope.unit.name?.substr(
                                                                0,
                                                                40
                                                            ),
                                                            value:
                                                                Scope.unit.id,
                                                        }
                                                        : null,
                                                    start_date_exec: todayOnlyDate,
                                                    end_date_exec:todayOnlyDate,
                                                    // start_sales_order:todayOnlyDate,
                                                    // end_sales_order:todayOnlyDate

                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {formProps => (
                                                    <Form
                                                    >

                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                className="mt-1"
                                                            >
                                                                <FormGroup>
                                                                    <Label>
                                                                        Profissional
                                                                    </Label>
                                                                    <Field
                                                                        name="professional_id"
                                                                        placeholder="Selecionar Profissional"
                                                                        defaultValue={
                                                                            formProps.professional_id
                                                                        }
                                                                        loadOptions={
                                                                            debounceOptionsProfessional
                                                                        }
                                                                        component={
                                                                            AsyncSelectInput
                                                                        }
                                                                        noOptionsMessage={() =>
                                                                            "Sem Resultados. Digite para Buscar o Profissional"
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>


                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Unidade
                                                                    </Label>
                                                                    <Field
                                                                        name={`unit_id`}
                                                                        placeholder="Selecione a Unidade"
                                                                        classNamePrefix="select2-selection"
                                                                        options={
                                                                            unitsOptions
                                                                        }
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                        component={
                                                                            ReactSelectInput
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>


                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="8"
                                                                
                                                            >
                                                                <FormGroup>
                                                                    <Label>
                                                                        Data Inicial
                                                                        Execução
                                                                    </Label>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        name={
                                                                            "start_date_exec"
                                                                        }
                                                                        type="date"
                                                                        defaultValue={
                                                                            formProps
                                                                                .values
                                                                                .start_date_exec
                                                                        }
                                                                    ></Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                    Data Final
                                                                    Execução
                                                                    </Label>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        name={
                                                                            "end_date_exec"
                                                                        }
                                                                        type="date"
                                                                        defaultValue={
                                                                            formProps
                                                                                .values
                                                                                .end_date_exec
                                                                        }
                                                                    ></Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>


                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                    Data Inicial
                                                                    Pedido de Venda
                                                                    </Label>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        name={
                                                                            "start_sales_order"
                                                                        }
                                                                        type="date"
                                                                        defaultValue={
                                                                            formProps
                                                                                .values
                                                                                .start_sales_order
                                                                        }
                                                                    ></Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row className="d-flex justify-content-center">
                                                            <Col sm="8">
                                                                <FormGroup>
                                                                    <Label>
                                                                    Data Final
                                                                    Pedido de Venda
                                                                    </Label>
                                                                    <Input
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        onBlur={
                                                                            formProps.handleBlur
                                                                        }
                                                                        name={
                                                                            "end_sales_order"
                                                                        }
                                                                        type="date"
                                                                        defaultValue={
                                                                            formProps
                                                                                .values
                                                                                .end_sales_order
                                                                        }
                                                                    ></Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                       

                                                        <Row className="d-flex justify-content-center">
                                                            <Col
                                                                sm="3"
                                                                align="start"
                                                                className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <div
                                                                        className="spinner-border text-dark"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">
                                                                            Loading...
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                            <Col
                                                                sm="5"
                                                                align="end"
                                                                className="mt-4"
                                                            >
                                                                {controlState ? (
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                        disabled
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                    >
                                                                        Gerar
                                                                        Relatório
                                                                        <i className="fas fa-user-cog ml-2 fa-lg"></i>
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OdontoExec
