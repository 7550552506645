import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    CardBody,
    CardTitle,
    CardSubtitle,
    FormGroup,
    Label,
    Button,
    Card,
    Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import { Link, useLocation } from "react-router-dom"
import logovitta from "assets/images/logovitta2.jpg"
import { useHistory } from "react-router-dom"
import { showDemonstrative } from "services/vitta-core/professional-transfer-invoices"

const DemonstrativeReport = ({ filter }) => {
    const history = useHistory()

    const [pageData, setPageData] = useState({
        header: null,
        data: [],
        totalPaid: 0,
        total_note_electronic_paid: 0,
        total_note_manual_paid: 0,
    })
    const [controlState, setControlState] = useState(false)
    useEffect(() => {
        const loadData = async () => {
            try {
                setControlState(true)
                let search = location.search
                const params = new URLSearchParams(search)
                const professional_id = params.get("professional_id")
                const unit_id = params.get("unit_id")
                const date_exec = params.get("date_exec")

                let result = await showDemonstrative({
                    date_exec: date_exec,
                    unit_id: unit_id,
                    professional_id: professional_id,
                })

                let totalNota = 0
                let totalPaid = 0
                let total_note_electronic_paid = 0
                let total_note_manual_paid = 0
                let total_advance_receipt = 0

                for (let i = 0; i < result?.record?.length; i++) {
                    let useInvoice = result?.record[i]
                    totalNota += calcTotalNota(useInvoice.items)

                    totalPaid += useInvoice.total_note_paid
                    total_note_electronic_paid +=
                        useInvoice.total_note_electronic_paid
                    total_note_manual_paid += useInvoice.total_note_manual_paid
                    total_advance_receipt += useInvoice.total_advance_receipt
                }

                setPageData({
                    header: {
                        unit: result?.unit,
                        professional: result?.professional,
                        date_exec: date_exec,
                    },
                    totalNota: totalNota / 100,
                    totalPaid: totalPaid / 100,
                    total_note_electronic_paid:
                        total_note_electronic_paid / 100,
                    total_note_manual_paid: total_note_manual_paid / 100,
                    total_advance_receipt: total_advance_receipt / 100,
                    data: result?.record,
                })
                setControlState(false)
            } catch (e) {
                console.log(e)
                setControlState(false)
            }
        }

        loadData()
    }, [])

    const calcTotalNota = items => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]

            total += useItem.quantity * (useItem.price ? useItem.price : 0)
        }

        return total
    }

    const printReport = () => {
        window.print()
    }

    return (
        <React.Fragment>
            <div className="page-content" translate="no">
                <Container fluid>
                    <Breadcrumbs
                        title="Relatórios"
                        breadcrumbItem="Repasse Médico"
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody translate="no">
                                    <div align="end">
                                        <Link
                                            to="/relatorios/repasses-medicos"
                                            className="btn btn-dark d-print-none mr-2"
                                        >
                                            <i className="fas fa-long-arrow-alt-left text-white fa-lg"></i>
                                        </Link>
                                        <Button
                                            className="btn btn-primary d-print-none"
                                            color="primary"
                                            onClick={printReport}
                                        >
                                            Imprimir
                                        </Button>
                                        <br />
                                        <br />
                                    </div>
                                    <Row>
                                        <Col
                                            md="8"
                                            className="d-flex justify-content-start align-items-center"
                                        >
                                            <img
                                                src={logovitta}
                                                alt=""
                                                height="60px"
                                                className="mb-2"
                                            />

                                            <div className="d-flex flex-column  ">
                                                <h4>
                                                    Unidade{" "}
                                                    {
                                                        pageData.header?.unit
                                                            ?.name
                                                    }
                                                </h4>
                                                <div className="">
                                                    CNPJ:{" "}
                                                    {
                                                        pageData.header?.unit
                                                            ?.cnpj
                                                    }
                                                </div>
                                            </div>
                                        </Col>

                                        <Col
                                            md="4"
                                            className="d-flex  flex-column "
                                        >
                                            <div className="">
                                                <strong>
                                                    Data Execução Serviços:
                                                </strong>{" "}
                                                {pageData.header?.date_exec
                                                    ? pageData.header.date_exec.substr(
                                                          8,
                                                          2
                                                      ) +
                                                      "/" +
                                                      pageData.header.date_exec.substr(
                                                          5,
                                                          2
                                                      ) +
                                                      "/" +
                                                      pageData.header.date_exec.substr(
                                                          0,
                                                          4
                                                      )
                                                    : null}
                                            </div>
                                            <div className="">
                                                <strong>Profissional:</strong>{" "}
                                                {pageData.header?.professional
                                                    ? pageData.header
                                                          ?.professional?.name
                                                    : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <h4 align="center">
                                        Demonstrativo de Repasse Médico
                                    </h4>
                                    <br />
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">ID Nota</th>
                                                <th scope="col">Data Nota</th>
                                                <th scope="col">Ped. Venda</th>
                                                {/* <th scope="col">ID OS</th> */}
                                                <th scope="col">Paciente</th>
                                                <th scope="col">Items Nota</th>

                                                <th scope="col">
                                                    Total Nota (R$)
                                                </th>

                                                <th scope="col">Pág. Caixa</th>

                                                <th scope="col">
                                                    Pág. Eletrônico
                                                </th>
                                                <th scope="col">
                                                    Rec. Antecipado
                                                </th>

                                                {/* <th scope="col">
                                                    Data Repasse
                                                </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageData.data.map((row, index) => (
                                                <tr>
                                                    <td>#{row.id}</td>
                                                    <td>
                                                        {row.created_at
                                                            ? new Date(
                                                                  row.created_at
                                                              ).toLocaleDateString(
                                                                  "pt-BR"
                                                              )
                                                            : null}{" "}
                                                    </td>
                                                    <td>
                                                        #
                                                        {row?.sales_order_id
                                                            ? row?.sales_order_id
                                                            : row?.os
                                                                  ?.sales_order_id
                                                            ? row?.os
                                                                  ?.sales_order_id
                                                            : ""}
                                                    </td>
                                                    {/* <td>#{row.os_id}</td> */}
                                                    <td>
                                                        {row.patient?.id +
                                                            " - " +
                                                            row.patient?.name}
                                                    </td>
                                                    <td>
                                                        {row.items.map(data => {
                                                            return (
                                                                <>
                                                                    {
                                                                        data
                                                                            .item
                                                                            .name
                                                                    }{" "}
                                                                    (
                                                                    {
                                                                        data.quantity
                                                                    }
                                                                    ) - R${" "}
                                                                    {new Intl.NumberFormat(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "decimal",
                                                                            currency:
                                                                                "BRL",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ).format(
                                                                        data.quantity *
                                                                            ((data.price
                                                                                ? data.price
                                                                                : 0) /
                                                                                100)
                                                                    )}
                                                                    <br />
                                                                </>
                                                            )
                                                        })}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                calcTotalNota(
                                                                    row.items
                                                                ) / 100
                                                            )}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row.total_note_manual_paid /
                                                                    100
                                                            )}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row.total_note_electronic_paid /
                                                                    100
                                                            )}
                                                    </td>
                                                    <td>
                                                        {"R$ " +
                                                            new Intl.NumberFormat(
                                                                "pt-br",
                                                                {
                                                                    style:
                                                                        "decimal",
                                                                    currency:
                                                                        "BRL",
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ).format(
                                                                row.total_advance_receipt
                                                                    ? row.total_advance_receipt /
                                                                          100
                                                                    : 0
                                                            )}
                                                    </td>
                                                    {/* <td>
                                                        {row.prof_transfer_invoice_payment
                                                            ? new Date(
                                                                  row.prof_transfer_invoice_payment.created_at
                                                              ).toLocaleDateString(
                                                                  "pt-BR"
                                                              )
                                                            : null}
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {controlState ? (
                                        <div
                                            className="spinner-border text-info"
                                            role="status"
                                            style={{
                                                position: "absolute",
                                                left: "633px",
                                                top: "410",
                                            }}
                                        >
                                            <span className="sr-only">
                                                Sincronizando...
                                            </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <br />

                                    <Row>
                                        <Col>
                                            Recebi do Caixa da Clínica Vittá a
                                            quantia de{" "}
                                            <strong>
                                                {" "}
                                                R${" "}
                                                {new Intl.NumberFormat(
                                                    "pt-br",
                                                    {
                                                        style: "decimal",
                                                        currency: "BRL",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ).format(
                                                    pageData.total_note_manual_paid
                                                )}{" "}
                                            </strong>{" "}
                                            referente a serviços prestados em
                                            parceria referenciados no
                                            demonstrativo.
                                            {pageData.total_note_electronic_paid >
                                            0 ? (
                                                <>
                                                <br />
                                                Estou ciente de que também receberei o valor de{" "}
                                                <strong>
                                                    R${" "}
                                                    {new Intl.NumberFormat("pt-br", {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }).format(pageData.total_note_electronic_paid)}
                                                </strong>{" "}
                                                através de transferência bancária, conforme o parcelamento realizado pelo paciente.
                                             </>
                                            ) : (
                                                ""
                                            )}
                                            {pageData.total_advance_receipt >
                                            0 ? (
                                                <>
                                                    <br />
                                                    Estou ciente que também
                                                    recebi antecipadamente o
                                                    valor de{" "}
                                                    <strong>
                                                        R${" "}
                                                        {new Intl.NumberFormat(
                                                            "pt-br",
                                                            {
                                                                style:
                                                                    "decimal",
                                                                currency: "BRL",
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ).format(
                                                            pageData.total_advance_receipt
                                                        )}
                                                        .
                                                    </strong>{" "}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                    <br />
                                    <strong> Data Recebimento: </strong>

                                    <br />
                                    <br />
                                    <br />
                                    <Row>
                                        <Col className="d-flex flex-column align-items-center">
                                            <div style={{ width: "300px" }}>
                                                <hr
                                                    style={{
                                                        borderTop:
                                                            "1px solid rgba(0, 0, 0, 0.7)",
                                                    }}
                                                ></hr>
                                            </div>
                                            <div>
                                                {" "}
                                                <strong>
                                                    {" "}
                                                    {
                                                        pageData.header
                                                            ?.professional?.name
                                                    }{" "}
                                                </strong>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DemonstrativeReport
