import React, { useState, useEffect, useRef, useMemo } from "react"
import {
    Row,
    Col,
    Modal,
    CardBody,
    Label,
    FormGroup,
    Input,
    UncontrolledTooltip,
    Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import { Formik, Field, Form, FieldArray, FastField } from "formik"
import AsyncSelect from "react-select/async"
import DENTAL_ARCH from "utils/consts/dental-arch"
import { search as partnerServiceSearch } from "services/vitta-core/partner"
import debounce from "debounce-promise"
import TYPE_PARTNERS from "utils/consts/type-partners"
import { update as updateQuote } from "services/vitta-core/sales-quote"
import { update as updateOrder } from "services/vitta-core/sales-order"
import { search as itemServiceSearch } from "services/vitta-core/item"
import { findExec } from "services/vitta-core/sales-order-item-exec"
import { create as createExec } from "services/vitta-core/sales-order-item-exec"
import { remove as removeExec } from "services/vitta-core/sales-order-item-exec"
import { showSweetAlertDialog, showToast } from "store/layout/actions"
import { findServiceWithStages } from "services/vitta-core/assessments"
import Loading from "./Components/GrowLoading"
import SALES_DOCS_TYPE from "utils/consts/sales-docs-type"
import ExecDetail from "../ExecStages"
import { useSelector, useDispatch } from "react-redux"
import SelectedProfessionalExecModal from "../SelectedProfessionalExecModal"
import SERVICES_TYPES from "utils/consts/services-types"
import { changeExecItem } from "services/vitta-core/sales-order"
import { findSOItemWithStages } from "services/vitta-core/assessments"
import SALES_ORDER_STATUS from "utils/consts/sales-orders-status"
const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
        noOptionsMessage={() =>
            "Sem Resultados. Preencha o procedimento e digite para Buscar"
        }
    />
)

const AsyncSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form,
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AsyncSelect
        {...field}
        {...props}
        isDisabled={props.disabled}
        onChange={async option => {
            if (field?.name == "laboratory_id") {
                setFieldValue(
                    "price_table_laboratory",
                    option?.partner_item?.cost_price
                        ? option?.partner_item?.cost_price
                        : 0
                )
            }
            if (field.name == "item_id") {
                setFieldValue("price_table", option?.price ? option?.price : 0)
                let getStages = []
                try {
                    getStages = await findServiceWithStages(option?.value)
                } catch (e) {
                    console.log(e)
                }

                setFieldValue(
                    "stages",
                    getStages?.sort((a, b) => a.sequence - b.sequence)
                )

                props.refreshPartnersDefaultOptions(option?.value)
            }
            setFieldValue(field.name, option)
        }}
        noOptionsMessage={() => "Sem Resultados. Digite para Buscar"}
        ref={props.Ref}
    />
)
const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" {...field} {...props} />

const ModalAssessment = ({
    show,
    data,
    details,
    loadDataEditPage,
    onClose,
    type,
    setFieldValue,
    doc_service_type,
}) => {
    const inputItem = useRef()
    const dispatch = useDispatch()
    const initialValues = {
        selected_teeths: [],
        laboratory_id: null,
        item_id: null,
        price_table: 0,
        price_table_laboratory: 0,
        stages: [],
        exec_item: null,
    }
    const [pageData, setPageData] = useState({
        defaultValues: initialValues,
    })

    const [modalExecDetail, setModalExecDetail] = useState({
        show: false,
        data: null,
    })
    const [defaultLaboratoryOptions, setDefaultLaboratoryOptions] = useState([])
    const [controlLoading, setControlLoading] = useState(false)
    const User = useSelector(state => state.auth.access.user)

    const hasPermissionExecItem = useMemo(() => {
        let found = false
        for (let i = 0; i < User?.access_roles?.length; i++) {
            let record = User?.access_roles[i]
            for (let j = 0; j < record?.permissions?.length; j++) {
                let result = record?.permissions[j]
                if (result.name == "add_service_exec") {
                    found = true
                    break
                }
            }
        }

        if (found) {
            return true
        } else {
            false
        }
    }, [User])

    const loadPartnerInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                let item_id = inputItem?.current?.select?.state?.value?.value
                if (inputValue == "") return resolve([])
                if (!item_id) {
                    dispatch(
                        showSweetAlertDialog({
                            title: "Atenção!",
                            text:
                                "Para buscar um laboratório é necessário selecionar um procedimento!",
                            type: "info",
                            showConfirm: true,
                        })
                    )
                    return
                }
                let response = await partnerServiceSearch(
                    {
                        term: inputValue,
                        status: 1,
                        type: TYPE_PARTNERS.DENTISTRY,
                        item_id: item_id,
                    },
                    1,
                    20
                )

                let partner_values = []
                response?.data?.forEach(element => {
                    partner_values.push({
                        label: `${element.id} - ` + element.name,
                        value: element.id,
                        partner_item: element?.partner_item?.find(
                            e => e?.item_id == item_id
                        ),
                    })
                })

                return resolve(partner_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsPartner = debounce(loadPartnerInputOptions, 600)

    const refreshPartnersDefaultOptions = async item_id => {
        try {
            let response = await partnerServiceSearch(
                {
                    status: 1,
                    type: TYPE_PARTNERS.DENTISTRY,
                    item_id: item_id,
                },
                1,
                20
            )

            let partner_values = []
            response?.data?.forEach(element => {
                partner_values.push({
                    label: `${element.id} - ` + element.name,
                    value: element.id,
                    partner_item: element?.partner_item?.find(
                        e => e?.item_id == item_id
                    ),
                })
            })

            setDefaultLaboratoryOptions([...partner_values])
        } catch (e) {
            console.log(e)
        }
    }

    const loadItemsInputOptions = async inputValue => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!inputValue) return resolve([])
                const statusActive = 1

                let response = await itemServiceSearch(
                    {
                        term: inputValue,
                        unit_id: data?.unit_id?.value,
                        status: statusActive,
                    },
                    1,
                    20
                )

                let items_values = []

                response.data.forEach(element => {
                    items_values.push({
                        label: element?.name,
                        value: element?.id,
                        price: element?.price_table_item?.price,
                        price_table_id:
                            element?.price_table_item?.price_table_id,
                        service_stages: element?.service_stages,
                        group_id: element?.group_id,
                    })
                })

                return resolve(items_values)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }
    const debounceItems = debounce(loadItemsInputOptions, 600)

    const onChecked = (values, teeth, isChecked) => {
        if (details) return
        let newTeeths = [...pageData.defaultValues.selected_teeths]
        if (isChecked) {
            newTeeths.push(teeth)
        } else {
            newTeeths = newTeeths.filter(e => e != teeth)
        }

        let newData = values
        newData.selected_teeths = newTeeths
        setPageData({
            defaultValues: newData,
        })
    }

    const onChangeSelectedStage = (setFieldValue, index, isChecked) => {
        setFieldValue(`stages[${index}].checked`, isChecked)
    }

    const getNowDateFormated = () => {
        var date = new Date()

        var ano = date.getFullYear()
        var mes = (date.getMonth() + 1).toString().padStart(2, "0")
        var dia = date.getDate().toString().padStart(2, "0")

        var dateFormated = ano + "-" + mes + "-" + dia
        return dateFormated
    }
    function formatDate(dateString) {
        const date = new Date(dateString, "yyyy-MM-dd 00:00:00")
        const year = date.getUTCFullYear()
        const month = String(date.getUTCMonth() + 1).padStart(2, "0")
        const day = String(date.getUTCDate()).padStart(2, "0")

        return `${day}/${month}/${year}`
    }

    const handleSubmit = async values => {
        setControlLoading(true)
        try {
            if (details) return

            if (!values?.item_id?.value) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text: "É necessário selecionar um procedimento!",
                        type: "info",
                        showConfirm: true,
                    })
                )
                setControlLoading(false)
                return
            }

            // let dataToSend = {
            //     id: data?.id,
            //     unit_id: data?.unit_id,
            //     patient_id: data?.patient_id,
            //     status: data?.status,
            //     items: [],
            // }

            let itemsToUpdate = [...data?.items]
            // if (data?.items?.length > 0) {
            //     for (let i = 0; i < data.items.length; i++) {
            //         let use = data?.items[i]
            //         itemsToUpdate.push({
            //             id: use?.id ? use?.id : null,
            //             exec_professional_id: use?.exec_professional_id,
            //             exec_in:use?.exec_in ? use?.exec_in : getNowDateFormated(),
            //             item_id: {item:{price_table_id:use?.item?.price_table_id,group_id:use?.item?.group_id},label:use?.item?.label,showInputPriceDisabled:true,value:use?.item?.value},
            //             price: (use?.price),
            //             price_table: (use?.price_table)*100,
            //             price_table_id: use?.price_table_id,
            //             pricing: use?.pricing,
            //             quantity: use?.quantity,
            //             lab_cost_id: use?.lab_cost_id,
            //             price_lab: use?.price_lab,
            //             tooth_number: use?.tooth_number,
            //         })
            //     }
            // }

            let dataToMap =
                values?.selected_teeths?.length > 0
                    ? values?.selected_teeths?.length
                    : 1

            for (let i = 0; i < dataToMap; i++) {
                let useTeeth = values?.selected_teeths[i]
                itemsToUpdate.push({
                    id: null,
                    doc_id: data?.id,
                    item: {
                        group_id: values?.item_id?.group_id,
                        id: values?.item_id?.value,
                        name: values?.item_id?.label,
                    },
                    exec_in: getNowDateFormated(),
                    item_id: {
                        item: {
                            price_table_id: values?.item_id?.price_table_id,
                            group_id: values?.item_id?.group_id,
                        },
                        label: values?.item_id?.label,
                        showInputPriceDisabled: true,
                        value: values?.item_id?.value,
                    },
                    quantity: 1,
                    price: values?.item_id?.price / 100,
                    price_table_id: values?.item_id?.price_table_id,
                    pricing: [],
                    lab_cost: {
                        id: values?.laboratory_id?.value,
                        name: values?.laboratory_id?.label,
                    },
                    lab_cost_id: values?.laboratory_id?.value,
                    price_lab: values?.laboratory_id?.partner_item?.cost_price,
                    tooth_number: useTeeth ? parseInt(useTeeth) : null,
                    price_table: values?.item_id?.price / 100,
                    // stages: values?.stages?.map(
                    //     (item, index) => item?.stage_id
                    // ),
                })
            }

            // dataToSend.items = itemsToUpdate
            setFieldValue("items", itemsToUpdate)

            // if (type == SALES_DOCS_TYPE.SALES_ORDER) {
            //     await updateOrder(data?.id, dataToSend)
            // } else {
            //     await updateQuote(data?.id, dataToSend)
            // }

            // dispatch(
            //     showToast({
            //         title: "Sucesso!",
            //         text: "Items atualizados!",
            //         type: "success",
            //     })
            // )
            // await loadDataEditPage()

            onClose()
        } catch (e) {
            console.log(e)
            setControlLoading(false)
            if (
                e?.response?.data?.error_code == "sales_order_active_payments"
            ) {
                dispatch(
                    showSweetAlertDialog({
                        title: "Atenção!",
                        text:
                            "Existem pagamentos feitos a este pedido, cancele-os primeiro.",
                        type: "warning",
                        showConfirm: true,
                    })
                )
                return
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Não foi possível adicionar esse procedimento a este documento, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
        setControlLoading(false)
    }
    const execItemStage = item => {
        return dispatch(
            showSweetAlertDialog({
                title:
                    "Tem certeza que deseja executar essa fase do procedimento?",
                // text: "Será registado o seu usuário como executor.",
                type: "warning",
                onConfirmAction: () => {
                    handleConfirmedExec(item)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Confirmar",
            })
        )
    }
    const execItem = values => {
        return dispatch(
            showSweetAlertDialog({
                title: "Tem certeza que deseja executar esse serviço?",
                // text: "Será registado o seu usuário como executor.",
                type: "warning",
                onConfirmAction: () => {
                    handleConfirmedExec()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Confirmar",
            })
        )
    }

    const handleExecItem = async sendData => {
        try {
            const dataToChange = {
                date_change: sendData?.exec_in,
                exec_professional_id: sendData?.exec_professional_id ?? null,
                stage_id: sendData?.service_stage_id ?? null,
            }
            await changeExecItem(data?.id, dataToChange)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Detalhes de execução atualizados com sucesso!",
                    type: "success",
                })
            )

            onClose({
                refreshOptions: {
                    refresh: true,
                    sales_order_item_id: data?.id,
                },
            })
        } catch (e) {
            console.log(e)
            if (e.response.data.error_code === "item_already_linked_to_transfer_note") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse item já esta vinculado a uma nota de repasse válida!",
                        text:"Verifique em notas de repasse, pela data de previsão de execução atual se não existe documento criado...",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            else if (e.response.data.error_code === "so_already_linked_to_sales_notes") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse pedido de venda já possui notas fiscais vinculadas!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }
            else if (e.response.data.error_code === "item_already_linked_to_sales_note") {
                dispatch(
                    showSweetAlertDialog({
                        title: "Esse item já esta vinculado a uma nota fiscal!",
                        type: "error",
                        showConfirm: true,
                    })
                )
                return
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Não foi possível realizar essa operação, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }

    const handleConfirmedExec = item => {
        const sendData = {
            sales_order_item_id: data?.id,
            service_stage_id: item?.stages ? item.stages.id : null,
            service_id: item?.service_id
                ? item?.service_id
                : data?.item_id?.value,
            exec_professional_id: null,
        }

        if (hasPermissionExecItem) {
            setModalExecDetail({
                show: true,
                data: sendData,
            })
        } else {
            handleExecItem(sendData)
        }
    }
    const confirmDeleteExec = id => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja remover esta execução?",
                type: "warning",
                onConfirmAction: () => {
                    deleteExec(id)
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const deleteExec = async id => {
        try {
            if (!id) return
            await removeExec(id)

            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Execução removida com sucesso!",
                    type: "success",
                })
            )
            await loadDataEditPage()
            loadDetails()
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Ops...",
                        text: e?.response?.data.message,
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
            dispatch(
                showSweetAlertDialog({
                    title: "Ops...",
                    text:
                        "Não foi possível remover essa execução, tente novamente mais tarde...",
                    type: "error",
                    showConfirm: true,
                })
            )
        }
    }
    const loadDetails = async () => {
        //console.log("data",data)
        setControlLoading(true)
        try {
            let dataForm = { ...pageData.defaultValues }

            dataForm.laboratory_id = {
                label: data?.lab_cost?.name,
                value: data?.lab_cost?.id,
            }
            dataForm.item_id = {
                label: data?.item_id?.label,
                value: data?.item_id?.value,
            }
            dataForm.price_table_laboratory = data?.price_lab
            dataForm.price_table = data?.price_table * 100
            dataForm.selected_teeths = [data?.tooth_number?.toString()]

         
            let stages = []
            let exec = null
            if(type == SALES_DOCS_TYPE.SALES_ORDER)
            {
                let itemExecData = await findSOItemWithStages(data?.id)

                for (let i = 0; i < itemExecData?.length; i++) {
                    let use = itemExecData[i]
                    stages.push({
                        percent_value: use?.percent_value ?? 0,
                        sequence: use?.stage_sequence ?? null,
                        service_id: use?.sales_order_item?.item?.id,
                        stages: [],
                        exec_stage: use?.procedure_stage ?? null,
                        exec_professional: use?.exec_professional ?? null,
                        exec_in: use?.exec_in ?? null,
                        mark_exec_at: use?.mark_exec_at ?? null,
                    })
                }
            }
            else
            {
                let getStages = await findServiceWithStages(data?.item_id?.value)
                const getExec = await findExec(data?.id)
                if (getStages?.length > 0) {
                   

                    for (let i = 0; i < getStages?.length; i++) {
                        let stage = getStages[i]
                        let stage_exec = null
                        if (getExec && getExec.length > 0) {
                            stage_exec = getExec.find(
                                e => e.stage_id == stage.stage_id
                            )
                        }
                        stages.push({
                            percent_value: stage?.percent_value,
                            sequence: stage?.sequence,
                            service_id: stage?.service_id,
                            stages: stage?.stages,
                            exec_stage: stage_exec,
                        })
                    }
                } 
                else {
                    if (getExec && getExec.length) exec = getExec[0]
                }
            }
            

            dataForm.exec_item = exec
            dataForm.stages = stages

            //console.log("teste",dataForm,data)
            setPageData({
                defaultValues: dataForm,
            })
        } catch (e) {
            console.log(e)
        }
        setControlLoading(false)
    }
    const clearData = () => {
        setPageData({
            defaultValues: initialValues,
        })
    }

    useEffect(() => {
        if (show) {
            if (details) {
                loadDetails()
            } else {
                clearData()
            }
        }
    }, [data])

    if (!show) return null
    return (
        <Modal
            isOpen={show}
            style={{ maxWidth: "85%" }}
            className="modal-custom-size"
        >
            <CardBody>
                <button
                    type="button"
                    onClick={() => {
                        onClose()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <Formik
                    initialValues={pageData.defaultValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {({ values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Row>
                                <Col sm="6">
                                    <div className="">
                                        <div className="" id="">
                                            {type ==
                                                SALES_DOCS_TYPE.SALES_ORDER &&
                                            details ? (
                                                <h4>
                                                    {`Item #${data?.item_id?.value} - ${data?.item_id?.label}`}
                                                </h4>
                                            ) : (
                                                <h4>
                                                    {!details
                                                        ? `Novo Procedimento`
                                                        : `Procedimento #${data?.id}`}
                                                </h4>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="6" align="end">
                                    {controlLoading ? <Loading /> : null}
                                </Col>
                            </Row>
                            <hr />
                            <div className="modal-body">
                                {doc_service_type == SERVICES_TYPES.DENTAL ? (
                                    <>
                                        {type == SALES_DOCS_TYPE.SALES_ORDER &&
                                        data?.id &&
                                        details ? (
                                            <Row>
                                                <Col>
                                                    {/* {console.log(data)} */}
                                                    <Row>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label>
                                                                    Laboratório
                                                                </Label>
                                                                <Field
                                                                    name={`laboratory_id`}
                                                                    defaultOptions={
                                                                        defaultLaboratoryOptions
                                                                    }
                                                                    placeholder="Digite para buscar..."
                                                                    loadOptions={
                                                                        debounceOptionsPartner
                                                                    }
                                                                    classNamePrefix="select2-selection"
                                                                    defaultValue={
                                                                        values?.laboratory_id
                                                                    }
                                                                    disabled={
                                                                        details
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label>
                                                                    Valor
                                                                    Laboratório
                                                                </Label>
                                                                <FastField
                                                                    name={`price_table_laboratory`}
                                                                    value={
                                                                        values?.price_table_laboratory
                                                                            ? (
                                                                                  values?.price_table_laboratory /
                                                                                  100
                                                                              ).toLocaleString(
                                                                                  "pt-br",
                                                                                  {
                                                                                      style:
                                                                                          "currency",
                                                                                      currency:
                                                                                          "BRL",
                                                                                  }
                                                                              )
                                                                            : "R$ 0,00"
                                                                    }
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    readOnly={
                                                                        true
                                                                    }
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f2f2f2",
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label>
                                                                    Numeração do
                                                                    Dente
                                                                </Label>
                                                                <FastField
                                                                    name={`tooth_number`}
                                                                    value={
                                                                        data?.tooth_number
                                                                    }
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    readOnly={
                                                                        true
                                                                    }
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f2f2f2",
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col sm="7">
                                                    <Row>
                                                        <Col sm="6">
                                                            <FormGroup>
                                                                <Label>
                                                                    Procedimento
                                                                </Label>
                                                                <Field
                                                                    name={`item_id`}
                                                                    placeholder="Digite para buscar..."
                                                                    defaultOptions
                                                                    Ref={
                                                                        inputItem
                                                                    }
                                                                    cacheOptions
                                                                    loadOptions={
                                                                        debounceItems
                                                                    }
                                                                    refreshPartnersDefaultOptions={
                                                                        refreshPartnersDefaultOptions
                                                                    }
                                                                    classNamePrefix="select2-selection"
                                                                    defaultValue={
                                                                        values?.item_id
                                                                    }
                                                                    disabled={
                                                                        details
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="6">
                                                            <FormGroup>
                                                                <Label>
                                                                    Laboratório
                                                                </Label>

                                                                <Field
                                                                    name={`laboratory_id`}
                                                                    placeholder="Digite para buscar..."
                                                                    defaultOptions={
                                                                        defaultLaboratoryOptions
                                                                    }
                                                                    loadOptions={
                                                                        debounceOptionsPartner
                                                                    }
                                                                    classNamePrefix="select2-selection"
                                                                    defaultValue={
                                                                        values?.laboratory_id
                                                                    }
                                                                    disabled={
                                                                        details
                                                                    }
                                                                    component={
                                                                        AsyncSelectInput
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="6">
                                                            <FormGroup>
                                                                <Label>
                                                                    Valor Tabela
                                                                </Label>
                                                                <FastField
                                                                    name={`price_table`}
                                                                    value={(
                                                                        values?.price_table /
                                                                        100
                                                                    ).toLocaleString(
                                                                        "pt-br",
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    readOnly={
                                                                        true
                                                                    }
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f2f2f2",
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm="6">
                                                            <FormGroup>
                                                                <Label>
                                                                    Valor
                                                                    Laboratório
                                                                </Label>
                                                                <FastField
                                                                    name={`price_table_laboratory`}
                                                                    value={
                                                                        values?.price_table_laboratory
                                                                            ? (
                                                                                  values?.price_table_laboratory /
                                                                                  100
                                                                              ).toLocaleString(
                                                                                  "pt-br",
                                                                                  {
                                                                                      style:
                                                                                          "currency",
                                                                                      currency:
                                                                                          "BRL",
                                                                                  }
                                                                              )
                                                                            : "R$ 0,00"
                                                                    }
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    readOnly={
                                                                        true
                                                                    }
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f2f2f2",
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm="5">
                                                    <div>
                                                        <h4 className="mt-2 mb-3">
                                                            Arcada Dentária
                                                        </h4>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "40px",
                                                                flexFlow:
                                                                    "wrap",
                                                            }}
                                                        >
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.superior.direito?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.superior.esquerdo?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.inferior.esquerdo?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.inferior.direito?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.deciduo.superior.direito?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.deciduo.superior.esquerdo?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.deciduo.inferior.esquerdo?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    {DENTAL_ARCH.arcos.deciduo.inferior.direito?.map(
                                                                        dente => (
                                                                            <div
                                                                                key={
                                                                                    dente.id
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    id={
                                                                                        dente.id
                                                                                    }
                                                                                    disabled={
                                                                                        details
                                                                                    }
                                                                                    name="selected_teeths"
                                                                                    onChange={e => {
                                                                                        onChecked(
                                                                                            values,
                                                                                            dente?.id,
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        )
                                                                                    }}
                                                                                    checked={values?.selected_teeths?.includes(
                                                                                        dente?.id
                                                                                    )}
                                                                                />{" "}
                                                                                <small
                                                                                    id={
                                                                                        "dente" +
                                                                                        dente?.id
                                                                                    }
                                                                                    htmlFor={
                                                                                        dente.id
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "12px",
                                                                                        cursor:
                                                                                            "pointer",
                                                                                    }}
                                                                                >
                                                                                    {/* {dente.name}{" "} */}

                                                                                    (
                                                                                    {
                                                                                        dente.id
                                                                                    }

                                                                                    )
                                                                                </small>
                                                                                <UncontrolledTooltip
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            "#0195d5",
                                                                                        color:
                                                                                            "white",
                                                                                    }}
                                                                                    placement="right"
                                                                                    target={
                                                                                        "dente" +
                                                                                        dente.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        dente?.name
                                                                                    }
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <hr />
                                    </>
                                ) : null}

                                {type == SALES_DOCS_TYPE.SALES_ORDER &&
                                data?.id &&
                                details ? (
                                    <>
                                        <Row>
                                            <Col>
                                                <h4>Detalhes da Execução</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col sm="12">
                                                <table
                                                    className="table"
                                                    style={{ width: "100%" }}
                                                >
                                                    <thead
                                                        style={{
                                                            backgroundColor:
                                                                "#f8f9fa",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        <tr>
                                                            <th scope="col">
                                                                Fase
                                                            </th>
                                                            <th scope="col">
                                                                Profissional
                                                                Execução
                                                            </th>
                                                            <th scope="col">
                                                                Data Prevista
                                                                Execução
                                                            </th>
                                                            <th scope="col">
                                                                Executado em
                                                            </th>
                                                            <th scope="col">
                                                                Ações
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            values?.stages
                                                                .length > 0
                                                                ? values?.stages?.map(
                                                                      (
                                                                          item,
                                                                          index
                                                                      ) => (
                                                                          <tr>
                                                                              <td align="start">
                                                                                  {
                                                                                      item
                                                                                          ?.exec_stage
                                                                                          ?.name
                                                                                  }
                                                                              </td>
                                                                              <td align="start">
                                                                                  {item
                                                                                      ?.exec_professional
                                                                                      ?.name ??
                                                                                      "Sem profissional"}
                                                                              </td>
                                                                              <td align="start">
                                                                                  {item?.exec_in
                                                                                      ? new Date(
                                                                                            item?.exec_in
                                                                                        ).toLocaleDateString(
                                                                                            "pt-BR"
                                                                                        )
                                                                                      : null}
                                                                              </td>
                                                                              <td align="start">
                                                                                  {item?.mark_exec_at
                                                                                      ? new Date(
                                                                                            item?.mark_exec_at
                                                                                        ).toLocaleDateString(
                                                                                            "pt-BR"
                                                                                        )
                                                                                      : "Não executado"}
                                                                              </td>
                                                                              <td
                                                                                  align="start"
                                                                                  style={{
                                                                                      paddingTop:
                                                                                          "4px",
                                                                                      fontWeight:
                                                                                          "bold",
                                                                                  }}
                                                                              >
                                                                                  {(data
                                                                                      ?.so_data
                                                                                      ?.status !=
                                                                                  SALES_ORDER_STATUS.CANCELED) || item?.mark_exec_at  || doc_service_type == SERVICES_TYPES.DENTAL  ? (
                                                                                      <>
                                                                                          {item?.mark_exec_at ? (
                                                                                              <Link
                                                                                                  to="#"
                                                                                                  className="text-success"
                                                                                              >
                                                                                                  <i className="fas fa-check"></i>{" "}
                                                                                                  Executado
                                                                                              </Link>
                                                                                          ) : (
                                                                                              <Link
                                                                                                  to="#"
                                                                                                  className="text-primary"
                                                                                                  onClick={() => {
                                                                                                      const sendData = {
                                                                                                          sales_order_item_id:
                                                                                                              data?.id,
                                                                                                          service_stage_id: item?.exec_stage
                                                                                                              ? item
                                                                                                                    ?.exec_stage
                                                                                                                    ?.id
                                                                                                              : null,
                                                                                                          service_id:
                                                                                                              item?.service_id ??
                                                                                                              null,
                                                                                                          exec_professional_id: item?.exec_professional ?? null,
                                                                                                          exec_in:item?.exec_in ?? null,
                                                                                                          so_status:data
                                                                                                          ?.so_data
                                                                                                          ?.status
                                                                                                      }

                                                                                                      setModalExecDetail(
                                                                                                          {
                                                                                                              show: true,
                                                                                                              data: sendData,
                                                                                                          }
                                                                                                      )
                                                                                                  }}
                                                                                              >
                                                                                                  <i
                                                                                                      className="fas fa-edit"
                                                                                                      id={
                                                                                                          "edittooltip" +
                                                                                                          item.sequence
                                                                                                      }
                                                                                                  />
                                                                                                  Alterar
                                                                                                  <UncontrolledTooltip
                                                                                                      placement="top"
                                                                                                      target={
                                                                                                          "edittooltip" +
                                                                                                          item.sequence
                                                                                                      }
                                                                                                      style={{
                                                                                                          backgroundColor:
                                                                                                              "#007bff",
                                                                                                          color:
                                                                                                              "white",
                                                                                                      }}
                                                                                                  >
                                                                                                      Alterar
                                                                                                  </UncontrolledTooltip>
                                                                                              </Link>
                                                                                          )}
                                                                                      </>
                                                                                  ) : data
                                                                                        ?.so_data
                                                                                        ?.electronic_vittapag_payment_status ? (
                                                                                      <>
                                                                                          {item?.mark_exec_at ? (
                                                                                              <Link
                                                                                                  to="#"
                                                                                                  className="text-success"
                                                                                              >
                                                                                                  <i className="fas fa-check"></i>{" "}
                                                                                                  Executado
                                                                                              </Link>
                                                                                          ) : (
                                                                                              <Link
                                                                                                  to="#"
                                                                                                  className="text-primary"
                                                                                                  onClick={() => {
                                                                                                      const sendData = {
                                                                                                          sales_order_item_id:
                                                                                                              data?.id,
                                                                                                          service_stage_id: item?.exec_stage
                                                                                                              ? item
                                                                                                                    ?.exec_stage
                                                                                                                    ?.id
                                                                                                              : null,
                                                                                                          service_id:
                                                                                                              item?.service_id ??
                                                                                                              null,
                                                                                                              exec_professional_id: item?.exec_professional ?? null,
                                                                                                              exec_in:item?.exec_in ?? null,
                                                                                                          so_status:data
                                                                                                          ?.so_data
                                                                                                          ?.status
                                                                                                      }

                                                                                                      setModalExecDetail(
                                                                                                          {
                                                                                                              show: true,
                                                                                                              data: sendData,
                                                                                                          }
                                                                                                      )
                                                                                                  }}
                                                                                              >
                                                                                                  <i
                                                                                                      className="fas fa-edit"
                                                                                                      id={
                                                                                                          "edittooltip" +
                                                                                                          item.sequence
                                                                                                      }
                                                                                                  />
                                                                                                  Alterar
                                                                                                  <UncontrolledTooltip
                                                                                                      placement="top"
                                                                                                      target={
                                                                                                          "edittooltip" +
                                                                                                          item.sequence
                                                                                                      }
                                                                                                      style={{
                                                                                                          backgroundColor:
                                                                                                              "#007bff",
                                                                                                          color:
                                                                                                              "white",
                                                                                                      }}
                                                                                                  >
                                                                                                      Alterar
                                                                                                  </UncontrolledTooltip>
                                                                                              </Link>
                                                                                          )}
                                                                                      </>
                                                                                  ) : null}
                                                                              </td>
                                                                          </tr>
                                                                      )
                                                                  )
                                                                : null
                                                            // ) : (
                                                            //     <tr>
                                                            //         <td align="start">
                                                            //             {""}
                                                            //         </td>
                                                            //         <td align="start">
                                                            //             {
                                                            //                values?.exec_data?.exec_professional?.label
                                                            //             }
                                                            //         </td>
                                                            //         <td align="start">
                                                            //         { values?.exec_data?.exec_in
                                                            //                         ? new Date(
                                                            //                             values?.exec_data?.exec_in
                                                            //                           ).toLocaleDateString(
                                                            //                               "pt-BR"
                                                            //                           )
                                                            //                         : null}
                                                            //         </td>
                                                            //         <td align="start">
                                                            //         { values?.exec_data?.mark_exec_at
                                                            //                         ? new Date(
                                                            //                             values?.exec_data?.mark_exec_at
                                                            //                           ).toLocaleDateString(
                                                            //                               "pt-BR"
                                                            //                           )
                                                            //                         : "Não executado"}
                                                            //         </td>
                                                            //         <td
                                                            //             align="start"
                                                            //             style={{
                                                            //                 paddingTop:
                                                            //                     "4px",
                                                            //             }}
                                                            //         >
                                                            //             {values?.exec_data?.mark_exec_at ? <Link to="#" className="text-success"><i className="fas fa-check"></i> Executado</Link> : <Link
                                                            //                     to="#"
                                                            //                     className="text-primary"
                                                            //                     onClick={() => {
                                                            //                         const sendData = {
                                                            //                             sales_order_item_id:
                                                            //                                 data?.id,
                                                            //                             service_stage_id: null,
                                                            //                             service_id:
                                                            //                                 values
                                                            //                                     ?.item_id
                                                            //                                     ?.value,
                                                            //                             exec_professional_id: null,
                                                            //                         }

                                                            //                         setModalExecDetail(
                                                            //                             {
                                                            //                                 show: true,
                                                            //                                 data: sendData,
                                                            //                             }
                                                            //                         )

                                                            //                         // execItem(
                                                            //                         //     values
                                                            //                         // )
                                                            //                     }}
                                                            //                 >
                                                            //                     <i
                                                            //                         className="fas fa-edit"
                                                            //                         id={
                                                            //                             "edittooltip" +
                                                            //                             values
                                                            //                                 ?.exec_item
                                                            //                                 ?.id
                                                            //                         }
                                                            //                     />
                                                            //                     Alterar
                                                            //                     <UncontrolledTooltip
                                                            //                         placement="top"
                                                            //                         target={
                                                            //                             "edittooltip" +
                                                            //                             values
                                                            //                                 ?.exec_item
                                                            //                                 ?.id
                                                            //                         }
                                                            //                         style={{
                                                            //                             backgroundColor:
                                                            //                                 "#007bff",
                                                            //                             color:
                                                            //                                 "white",
                                                            //                         }}
                                                            //                     >
                                                            //                         Alterar
                                                            //                     </UncontrolledTooltip>
                                                            //                 </Link>}

                                                            //         </td>
                                                            //     </tr>
                                                            // )
                                                        }
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </>
                                ) : values?.stages?.length > 0 ? (
                                    <>
                                        <Row>
                                            <Col>
                                                <h4>Fases do procedimento</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col sm="12">
                                                <table
                                                    className="table"
                                                    style={{ width: "100%" }}
                                                >
                                                    <thead
                                                        style={{
                                                            backgroundColor:
                                                                "#0195d5",
                                                            color: "white",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        <tr>
                                                            <th scope="col">
                                                                Fase
                                                            </th>
                                                            {/* <th scope="col">
                                                                S/N
                                                            </th> */}
                                                            <th scope="col">
                                                                Percentual (%)
                                                            </th>
                                                            {/* {type == SALES_DOCS_TYPE.SALES_ORDER?(
                                                            <th scope="col">Ações</th>
                                                        ):''} */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values?.stages?.map(
                                                            (item, index) => (
                                                                <tr
                                                                    key={
                                                                        "key" +
                                                                        index
                                                                    }
                                                                >
                                                                    <td
                                                                        style={{
                                                                            width:
                                                                                "85%",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item
                                                                                ?.stages
                                                                                ?.name
                                                                        }
                                                                    </td>
                                                                    {/* <td
                                                                        style={{
                                                                            width:
                                                                                "15%",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                left:
                                                                                    "25px",
                                                                            }}
                                                                        >
                                                                            <Input
                                                                                type="checkbox"
                                                                                name="selected_stages"
                                                                                style={{
                                                                                    transform:
                                                                                        "scale(1.2)",
                                                                                    cursor:
                                                                                        "pointer",
                                                                                }}
                                                                                disabled
                                                                                onChange={e => {
                                                                                    // onChangeSelectedStage(
                                                                                    //     setFieldValue,
                                                                                    //     index,
                                                                                    //     e
                                                                                    //         ?.target
                                                                                    //         ?.checked
                                                                                    // )
                                                                                }}
                                                                                checked
                                                                            />{" "}
                                                                        </div>
                                                                    </td> */}
                                                                    <td
                                                                        style={{
                                                                            width:
                                                                                "15%",
                                                                            position:
                                                                                "relative",
                                                                            left:
                                                                                "22px",
                                                                        }}
                                                                    >
                                                                        {item?.percent_value /
                                                                            100}{" "}
                                                                        %
                                                                    </td>
                                                                    {/* {type && type === SALES_DOCS_TYPE.SALES_ORDER ? (
                                                                    !item?.exec_stage ? (
                                                                        <td className='p-0'>
                                                                        
                                                                        </td>
                                                                    ) : (
                                                                        <td className='p-0'>
                                                                        <Link to='#' onClick={() => setModalExecDetail({
                                                                            show:true,
                                                                            data:item
                                                                        })} style={{ marginLeft: '10px', paddingTop: '10px', paddingRight: '5px' }}>
                                                                            <i className="far fa-eye font-size-24 ml-1 mt-2 text-primary " id={"edittooltip" + index} />
                                                                        </Link>
                                                                        <UncontrolledTooltip placement="top" target={"edittooltip" + index}>
                                                                            Visualizar
                                                                        </UncontrolledTooltip>
                                                                        </td>
                                                                    )
                                                                    ) : (
                                                                    ''
                                                                    )} */}
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                            </div>
                            <Row className="">
                                <Col align="end">
                                    <Button
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                            onClose()
                                        }}
                                        disabled={controlLoading}
                                    >
                                        Fechar
                                    </Button>{" "}
                                    {details ? null : (
                                        <Button
                                            size="sm"
                                            type="submit"
                                            className="ml-1"
                                            color="info"
                                            disabled={controlLoading}
                                        >
                                            {!controlLoading
                                                ? "Adicionar procedimento"
                                                : "Adicionando..."}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </CardBody>
            <SelectedProfessionalExecModal
                show={modalExecDetail.show}
                data={modalExecDetail.data}
                onClose={() => {
                    setModalExecDetail({
                        show: false,
                        data: null,
                    })
                }}
                handleExecItem={handleExecItem}
                doc_service_type={doc_service_type}
                electronic_vittapag_payment_status={data
                    ?.so_data
                    ?.electronic_vittapag_payment_status}
            />
        </Modal>
    )
}

export default ModalAssessment
